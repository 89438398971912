<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    @click:outside="close"
    width="800"
  >
    <v-card flat>
      <v-card-title>
        {{ $t('addNewDeliveryMethod') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('selectADeliveryMethodBelowToAdd') }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text class="pb-0 px-0">
        <DeliveryMethodsList
          :delivery-methods="deliveryMethods"
          v-on="$listeners"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row
            dense
            justify="space-between"
          >
            <v-col
              cols="12"
              md="auto"
              class="py-0"
            >
              <v-btn
                @click="close"
                block
                large
                text
                color="error"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DeliveryMethodsList from "@/components/deliveryMethod/DeliveryMethodsList"

export default {
	name: "DeliveryMethodsDialog",
	components: {
		DeliveryMethodsList
	},
	props: {
		value: {
      type: Boolean
    },
		deliveryMethods: {
      type: Array,
      default: () => []
    }
	},
	methods: {
		close() {
			this.$emit('closed')
		}
	}
}
</script>

<style scoped>

</style>