<template>
  <v-list>
    <template v-for="(deliveryMethod, index) in deliveryMethods">
      <DeliveryMethodsListItem
        :delivery-method="deliveryMethod"
        :key="index"
        v-on="$listeners"
      />
    </template>
  </v-list>
</template>

<script>
import DeliveryMethodsListItem from "@/components/deliveryMethod/DeliveryMethodsListItem"

export default {
	name: "DeliveryMethodsList",
	components: {
		DeliveryMethodsListItem
	},
	props: {
		deliveryMethods: {
      type: Array,
      default: () => []
    }
	}
}
</script>

<style scoped>

</style>